import React from "react";
import {
  CardElement,
  Elements,
  useElements,
  useStripe,
} from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";
import { useMutation } from "@apollo/client";
import styled from "@emotion/styled";

import {
  createCheckoutSessionMutation,
  updatePaymentMethodMutation,
} from "../../graphql/auth";
import colors from "../../colors";

const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_PUBLISHABLE_KEY!);

const Container = styled.div({
  display: "flex",
  flexDirection: "column",
  justifyContent: "center",
  alignItems: "center",
  "div, p": {
    width: "60%",
  },
});

const Card = styled.div({
  backgroundColor: colors.Tertiary,
  borderRadius: "5px",
  boxShadow: "5px 5px 10px rgba(0,0,0,0.5)",
  padding: "10px",
  marginTop: "1.5rem",
});

const Button = styled.button({
  backgroundColor: colors.Primary,
  border: "none",
  borderRadius: "2px",
  color: colors.Secondary,
  display: "block",
  fontSize: "17px",
  padding: "0.5rem",
  marginTop: "12px",
});

const SubscriptionForm = () => {
  const stripe = useStripe();
  const elements = useElements();

  const [createCheckoutSession] = useMutation(createCheckoutSessionMutation);
  const [updatePaymentMethod] = useMutation(updatePaymentMethodMutation);

  const handleSubmit = async () => {
    console.log("Submit!");
    if (!stripe || !elements) {
      return;
    }

    const resp = await createCheckoutSession({
      variables: {
        priceId: process.env.REACT_APP_STRIPE_PRICE_ID!,
      },
    });

    if (resp.errors || !resp.data) {
      return;
    }

    stripe.redirectToCheckout({
      sessionId: resp.data.createCheckoutSession,
    });

    if (resp.errors || !resp.data) {
      return;
    }

    const cardElement = elements.getElement(CardElement);
    if (!cardElement) {
      return;
    }
    const { error, paymentMethod } = await stripe.createPaymentMethod({
      type: "card",
      card: cardElement,
    });
    if (error) {
      console.log(error);
      return;
    }
    if (!paymentMethod) {
      return;
    }
    console.log(paymentMethod);
    const res = await updatePaymentMethod({
      variables: {
        paymentMethod: paymentMethod.id,
      },
    });
    console.log(res);
  };
  return (
    <Container>
      <p style={{ fontSize: "20px" }}>Subscribe to search for wells!</p>
      <p style={{ fontSize: "17px" }}>
        $300 per month, recurring on the same day every month, i.e. again on the{" "}
        {new Date().getDate()} of next month.
      </p>
      <Card>
        <Button onClick={handleSubmit}>Subscribe</Button>
        {/* <CardElement */}
        {/* options={{
            hidePostalCode: true,
            iconStyle: "solid",
            style: {
              base: {
                fontWeight: "500",
                fontSize: "16px",
                fontSmoothing: "antialiased",
              },
            },
          }}
          onReady={(e) => e.focus()}
        />
        <Button type="button" disabled={!stripe} onClick={handleSubmit}>
          Submit
        </Button> */}
      </Card>
    </Container>
  );
};

const Subscribe = () => {
  return (
    <Elements stripe={stripePromise}>
      <SubscriptionForm />
    </Elements>
  );
};

export default Subscribe;
