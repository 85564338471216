import React from "react";
import { BrowserRouter } from "react-router-dom";

import { AuthProvider } from "./contexts/AuthContext";
import Router from "./navigation";
import ApolloProvider from "./graphql/Provider";
import NavBar from "./components/navbar";

import "./App.css";

const App = () => (
  <BrowserRouter>
    <AuthProvider>
      <ApolloProvider>
        <div>
          <NavBar />
        </div>
        <Router />
      </ApolloProvider>
    </AuthProvider>
  </BrowserRouter>
);

export default App;
