import React, { useContext, useReducer } from "react";

type AuthData = {
  user?: {
    id: string;
    email: string;
    subscription: string;
  };
  tokens: {
    accessToken: string;
    refreshToken: string;
  };
};

const initialData = {
  tokens: {
    accessToken: "",
    refreshToken: "",
  },
};

type Action =
  | {
      type: "Login";
      payload: {
        user: {
          id: string;
          email: string;
          subscription: string;
        };
        tokens: {
          accessToken: string;
          refreshToken: string;
        };
      };
    }
  | {
      type: "REFRESH_SUCCESS";
      payload: {
        accessToken: string;
        refreshToken: string;
      };
    }
  | {
      type: "LOGOUT";
    };

const authReducer = (initialState: AuthData, action: Action) => {
  switch (action.type) {
    case "Login":
    case "REFRESH_SUCCESS":
      const newState = {
        ...initialState,
        ...action.payload,
      };
      localStorage.setItem("pul-auth", JSON.stringify(newState));
      return newState;
    case "LOGOUT":
      localStorage.removeItem("pul-auth");
      return initialData;
    default:
      return initialState;
  }
};

const authStateContext = React.createContext<AuthData>(initialData);

export const useAuthState = () => {
  const context = useContext(authStateContext);
  return context;
};

const authDispatchContext = React.createContext<React.Dispatch<Action>>(
  () => {}
);

export const useAuthDispatch = () => {
  const context = useContext(authDispatchContext);
  return context;
};

export const AuthProvider: React.FunctionComponent = ({ children }) => {
  const [authData, authDispatch] = useReducer(
    authReducer,
    initialData,
    (initialState) => {
      const authStr = localStorage.getItem("pul-auth");
      if (!authStr) {
        return initialState;
      }
      const auth = JSON.parse(authStr);
      return { ...initialState, ...auth };
    }
  );
  return (
    <authStateContext.Provider value={authData}>
      <authDispatchContext.Provider value={authDispatch}>
        {children}
      </authDispatchContext.Provider>
    </authStateContext.Provider>
  );
};
