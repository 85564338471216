import React from "react";
import styled from "@emotion/styled";
import { useHistory } from "react-router-dom";

import colors from "../../colors";
import { useAuthDispatch } from "../../contexts/AuthContext";

const Nav = styled.div({
  backgroundColor: colors.Dark,
  overflow: "hidden",
  listStyle: "none",
});

const NavItem = styled.div(({ right }: { right?: boolean }) => ({
  float: right ? "right" : "left",
  color: colors.Light,
  textAlign: "center",
  padding: "14px 16px",
  textDecoration: "none",
  fontSize: "17px",
  cursor: "pointer",
  "&:hover": {
    backgroundColor: colors.Primary,
    color: colors.Dark,
    borderRadius: "2px",
  },
}));

const NavBar = () => {
  const authDispatch = useAuthDispatch();
  const history = useHistory();
  return (
    <nav>
      <Nav>
        <NavItem onClick={() => history.push("/")}>Home</NavItem>
        <NavItem onClick={() => history.push("/subscribe")}>Subscribe</NavItem>
        <NavItem
          right
          onClick={() => {
            authDispatch({ type: "LOGOUT" });
          }}
        >
          Log Out
        </NavItem>
      </Nav>
    </nav>
  );
};

export default NavBar;
