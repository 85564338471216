import React from "react";
import { useMutation } from "@apollo/client";
import { useForm } from "react-hook-form";
import styled from "@emotion/styled";
import { useHistory } from "react-router-dom";

import { loginMutation } from "../../graphql/auth";
import { useAuthDispatch } from "../../contexts/AuthContext";
import colors from "../../colors";

type FormValues = {
  email: string;
  password: string;
};

const Container = styled.div({
  display: "flex",
  flexDirection: "column",
});

const Card = styled.div({
  backgroundColor: colors.Tertiary,
  borderRadius: "5px",
  boxShadow: "5px 5px 10px rgba(0,0,0,0.5)",
  padding: "10px",
  marginTop: "1.5rem",
  width: "60%",
});

const Label = styled.label({
  width: "50%",
  display: "flex",
  flexDirection: "row",
  justifyContent: "space-between",
  margin: "4px",
  span: {
    flex: "1",
  },
  input: {
    flex: "3",
    marginInlineStart: "20px",
  },
});

const Login = () => {
  const history = useHistory();
  const authDispatch = useAuthDispatch();
  const { register, handleSubmit } = useForm<FormValues>();
  const [login] = useMutation(loginMutation);

  const onSubmit = async ({ email, password }: FormValues) => {
    try {
      const resp = await login({
        variables: {
          login: {
            email,
            password,
          },
        },
      });
      if (resp.errors || !resp.data) {
        console.log(resp.errors);
        return;
      }
      authDispatch({
        type: "Login",
        payload: resp.data.login,
      });
      history.push("/");
    } catch (err) {
      console.log("Caught error", err);
    }
  };
  return (
    <Container>
      <h1>Find Pulling Units</h1>
      <Card>
        <form onSubmit={handleSubmit(onSubmit)}>
          <Label htmlFor="email">
            <span>Email</span>
            <input id="email" name="email" ref={register({ required: true })} />
          </Label>
          <Label htmlFor="password">
            <span>Password</span>
            <input
              id="password"
              name="password"
              ref={register({ required: true })}
            />
          </Label>
          <button type="submit">Submit</button>
        </form>
      </Card>
    </Container>
  );
};

export default Login;
