import React from "react";
import { Switch, Route, Redirect } from "react-router-dom";
import { useAuthState } from "../contexts/AuthContext";

import Home from "../components/home";
import Login from "../components/login";
import Subscribe from "../components/subscribe";
import { CheckoutSuccess } from "../components/checkout";
import CheckoutRedirector from "../components/checkoutredirect";

const Router = () => {
  const authState = useAuthState();
  console.log(authState);
  if (!authState.tokens.accessToken) {
    return (
      <Switch>
        <Route exact path="/login" component={Login} />
        <Route path="/checkout/:id" component={CheckoutRedirector} />
        <Route render={() => <Redirect to="/login" />} />
      </Switch>
    );
  }
  return (
    <Switch>
      <Route exact path="/" component={Home} />
      <Route exact path="/subscribe" component={Subscribe} />
      <Route exact path="/checkout-success" component={CheckoutSuccess} />
    </Switch>
  );
};

export default Router;
