import { useMutation } from "@apollo/client";
import styled from "@emotion/styled";
import React from "react";
import { useHistory } from "react-router-dom";
import { lighten } from "polished";

import { useAuthState } from "../../contexts/AuthContext";
import {
  createCheckoutSessionMutation,
  linkStripeMutation,
} from "../../graphql/auth";
import colors from "../../colors";

const Main = styled.main({
  alignItems: "center",
  display: "flex",
  flexDirection: "column",
  height: "100%",
  justifyContent: "center",
  width: "100%",
});

const UserInfo = styled.div({
  backgroundColor: colors.Tertiary,
  borderRadius: "5px",
  boxShadow: "5px 5px 10px rgba(0,0,0,0.5)",
  padding: "10px",
  width: "60%",
});

const FieldLabel = styled.p({
  fontsize: "20px",
  fontWeight: 500,
});

const FieldContents = styled.p({
  fontSize: "17px",
  fontWeight: 400,
  paddingLeft: "8px",
});

const Button = styled.button({
  backgroundColor: colors.Primary,
  border: "none",
  borderRadius: "2px",
  color: colors.Secondary,
  display: "block",
  fontSize: "17px",
  padding: "0.5rem",
});

const Home = () => {
  const authState = useAuthState();
  const history = useHistory();
  const [linkStripe] = useMutation(linkStripeMutation);
  return (
    <Main>
      <h1>Find Pulling Units</h1>
      <UserInfo>
        <FieldLabel>Email</FieldLabel>
        <FieldContents>{authState.user?.email}</FieldContents>
        <FieldLabel>Subscription</FieldLabel>
        <FieldContents>{authState.user?.subscription}</FieldContents>
        <Button
          type="button"
          onClick={() => {
            history.push("/subscribe");
          }}
        >
          Subscribe!
        </Button>
        <Button
          type="button"
          onClick={async () => {
            const resp = await linkStripe();
            if (resp.errors || !resp.data) {
              return;
            }
            window.location.replace(resp.data.linkStripe);
          }}
        >
          Link Stripe to get paid
        </Button>
      </UserInfo>
    </Main>
  );
};

export default Home;
