import { stripIgnoredCharacters } from "graphql";
import React, { useEffect } from "react";
import { useParams } from "react-router-dom";
import { loadStripe } from "@stripe/stripe-js";

const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_PUBLISHABLE_KEY!);

const CheckoutRedirector = () => {
  const { id } = useParams<{ id: string }>();
  useEffect(() => {
    const fn = async () => {
      const stripe = await stripePromise;
      await stripe?.redirectToCheckout({
        sessionId: id,
      });
    };
    fn();
  }, [id]);

  return (
    <>
      <p>You should be redirected to Stripe to finish subscribing</p>
    </>
  );
};

export default CheckoutRedirector;
