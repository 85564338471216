import { gql } from "@apollo/client";

export const loginMutation = gql`
  mutation login($login: Login!) {
    login(input: $login) {
      user {
        id
        email
        subscription
      }
      tokens {
        accessToken
        refreshToken
      }
    }
  }
`;

export const updatePaymentMethodMutation = gql`
  mutation updatePaymentMethod($paymentMethod: String!) {
    updatePaymentMethod(paymentMethod: $paymentMethod)
  }
`;

export const linkStripeMutation = gql`
  mutation linkStripe {
    linkStripe
  }
`;

export const createCheckoutSessionMutation = gql`
  mutation createCheckoutSession($priceId: String!) {
    createCheckoutSession(priceId: $priceId)
  }
`;

export const checkoutSuccessMutation = gql`
  mutation checkoutSuccess($sessionId: String!) {
    checkoutSuccess(sessionId: $sessionId)
  }
`;
