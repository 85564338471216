import React, { useMemo } from "react";
import {
  ApolloClient,
  ApolloLink,
  ApolloProvider,
  concat,
  HttpLink,
  InMemoryCache,
  ServerParseError,
} from "@apollo/client";
import { onError } from "@apollo/link-error";

import { useAuthDispatch, useAuthState } from "../contexts/AuthContext";

const apiBase = process.env.REACT_APP_API_BASE;

type Tokens = {
  accessToken: string;
  refreshToken: string;
};

const myFetch = (
  accessToken: string,
  refreshToken: string,
  saveTokens: (tokens: Tokens) => void
) => {
  let localToken = accessToken;
  const f = async (input: RequestInfo, origInit?: RequestInit) => {
    let init = origInit;
    if (init && init.headers) {
      init.headers = {
        ...init.headers,
        Authorization: `Bearer ${localToken}`,
      };
    }
    let initialResp: Response;
    try {
      initialResp = await fetch(input, init);
    } catch (e) {
      throw new Error(e);
    }
    if (initialResp.status !== 401) {
      return initialResp;
    }

    let refreshResp: Response;
    try {
      refreshResp = await fetch(`${apiBase}/refresh`, {
        method: "post",
        body: JSON.stringify({ refreshToken, accessToken }),
      });
    } catch (e) {
      throw new Error(e);
    }

    if (refreshResp.status === 401) {
      return initialResp;
    }

    if (refreshResp.status !== 201) {
      throw new Error("server error");
    }

    try {
      const json = await refreshResp.json();
      if (init && init.headers) {
        init.headers = {
          ...init.headers,
          Authorization: `Bearer ${json.accessToken}`,
        };
      } else {
        init = {
          headers: {
            Authorization: `Bearer ${json.accessToken}`,
          },
        };
      }
      localToken = json.accessToken;
      saveTokens(json);
      return fetch(input, init);
    } catch (e) {
      throw new Error(e);
    }
  };
  return f;
};

const httpLink = (
  accessToken: string,
  refreshToken: string,
  saveTokens: (tokens: Tokens) => void
) => {
  return new HttpLink({
    uri: `${apiBase}/graphql`,
    fetch: myFetch(accessToken, refreshToken, saveTokens),
  });
};

const authMiddleware = (accessToken: string) =>
  new ApolloLink((operation, forward) => {
    if (accessToken !== "") {
      operation.setContext({
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      });
    }
    return forward(operation);
  });

const logoutLink = (logout: () => void) =>
  onError(({ graphQLErrors, networkError }) => {
    if (networkError?.name === "ServerParseError") {
      const serverError = networkError as ServerParseError;
      if (serverError.statusCode === 401) logout();
    }
  });

const newCache = () => {
  return new InMemoryCache({
    typePolicies: {
      Query: {
        fields: {
          project: {
            merge: true,
          },
        },
      },
      Well: {
        merge: (_existing, incoming) => incoming,
      },
    },
  });
};

const newClient = (
  accessToken: string,
  refreshToken: string,
  cache: InMemoryCache,
  saveTokens: (tokens: Tokens) => void,
  logout: () => void
) => {
  return new ApolloClient({
    link: logoutLink(logout).concat(
      concat(
        authMiddleware(accessToken),
        httpLink(accessToken, refreshToken, saveTokens)
      )
    ),
    cache,
  });
};

const Provider: React.FunctionComponent = ({ children }) => {
  const {
    tokens: { accessToken, refreshToken },
  } = useAuthState();
  const authDispatch = useAuthDispatch();
  const cache = useMemo(() => newCache(), []);
  const client = useMemo(
    () =>
      newClient(
        accessToken,
        refreshToken,
        cache,
        (tokens: Tokens) => {
          authDispatch({
            type: "REFRESH_SUCCESS",
            payload: tokens,
          });
        },
        () => {
          authDispatch({
            type: "LOGOUT",
          });
        }
      ),
    [accessToken, refreshToken, authDispatch, cache]
  );
  return <ApolloProvider client={client}>{children}</ApolloProvider>;
};

export default Provider;
