import { useMutation } from "@apollo/client";
import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import { checkoutSuccessMutation } from "../../graphql/auth";

// A custom hook that builds on useLocation to parse
// the query string for you.
function useQuery() {
  return new URLSearchParams(useLocation<{ session_id: string }>().search);
}

const CheckoutSuccess = () => {
  const query = useQuery();
  const [checkoutSuccess] = useMutation(checkoutSuccessMutation, {
    variables: { sessionId: query.get("session_id") },
  });
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(false);
  useEffect(() => {
    console.log("Doin the effect");
    (async () => {
      const resp = await checkoutSuccess();
      if (resp.errors || !resp.data) {
        setLoading(false);
        setError(true);
      }
      setLoading(false);
    })();
  }, [checkoutSuccess]);

  if (loading) {
    return <p>Loading...</p>;
  }

  if (error) {
    return (
      <p>
        Uh oh, there was an error. Don&apos;t worry, we&apos;ll still process
        your request as soon as possible!
      </p>
    );
  }

  return (
    <p>
      Thank you for subscribing! Return to the app to start finding some work!
    </p>
  );
};

export default CheckoutSuccess;
